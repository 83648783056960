.twinkling-stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 1;
}

.star {
    color: white;
    position: absolute;
    background-color: #14fdce;
    animation: twinkle 2s infinite;
}

@keyframes twinkle {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}