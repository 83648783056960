.account-container {
    margin-top: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.account-container > div > h1 {
    margin-top: 0;
    line-height: 4rem;
}

.account-labels {
    flex-grow: 1;
    padding: 0 35px;
}

.account-labels > input {
    align-self: flex-end;
    width: 60%;
    float: right;
}

.account-labels > label {
    margin-right: 20px;
}

.save-button {
    width: 80px;
    height: 40px;
    align-self: center;
}

