.app {
  padding: 0 25px;
}

.square-container {
    min-width: 50px;
    position: relative;
    height: 50px;
    overflow: hidden;
    align-items: center;
    display: flex;
}

.square-container img {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

input {
  background-color: #000;
  color: #14fdce;
  font-family: "Courier New", monospace; /* Monospace font for the terminal feel */
  font-size: 14px;
  padding: 5px;
  border: 1px solid #14fdce;
  border-radius: 3px;
  width: 50%;
  min-width: 200px;
  max-width: 300px;
  outline: none;
  caret-color: #14fdce;
  margin-bottom: 20px;
}

input:focus {
  border: 1px solid #0F0; /* Green border when focused */
  box-shadow: 0 0 5px #0F0; /* Add a slight glow when focused */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #14fdce;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #000;
}

input::file-selector-button, .button {
  background-color: #000; /* Background color - usually black */
  color: #14fdce; /* Text color - often green or white */
  font-family: "Courier New", monospace; /* Monospace font for the terminal feel */
  font-size: 14px; /* Adjust the font size as needed */
  padding: 5px 10px; /* Padding for button content */
  border: 1px solid #14fdce; /* Green border around the button */
  border-radius: 3px;
  cursor: pointer; /* Cursor style to indicate interactivity */
  text-align: center; /* Center button text */
  outline: none; /* Remove the default focus outline */
}

.button:hover {
  background-color: #0F0; /* Green background on hover */
  color: #000; /* Text color on hover */
}

.button:focus {
  box-shadow: 0 0 5px #0F0; /* Add a slight glow when focused */
}

.scanline {
  position: absolute;
  top: 0em;
  left: 0;
  right: 0;
  height: .2em;
  opacity: 0;
  background: linear-gradient(to bottom, transparent 0%, rgba(255, 250, 250, 1) 50%, rgba(100, 255, 100, 1) 50%, transparent 100%);;
}

.scanline.start {
  top: 100%;
  opacity: 0.1;
  transition: 1.5s top linear;
}

.pixelated-heart {
    display: block;
    width: 10px;
    height: 10px;
    box-shadow:
          10px 00px #F22613,
          20px 00px #F22613,
          40px 00px #F22613,
          50px 00px #F22613,

          00px 10px #F22613,
          10px 10px #F22613,
          20px 10px #F22613,
          30px 10px #F22613,
          40px 10px #F22613,
          50px 10px #F22613,
          60px 10px #F22613,

          00px 20px #F22613,
          10px 20px #F22613,
          20px 20px #F22613,
          30px 20px #F22613,
          40px 20px #F22613,
          50px 20px #F22613,
          60px 20px #F22613,

          10px 30px #F22613,
          20px 30px #F22613,
          30px 30px #F22613,
          40px 30px #F22613,
          50px 30px #F22613,

          20px 40px #F22613,
          30px 40px #F22613,
          40px 40px #F22613,

          30px 50px #F22613;
}

.screen-turn-effect {
    background-color: transparent;
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 50%;
    width: inherit;
    height: inherit;
    transform: translate(-50%, -50%);
    content: " ";
    overflow: hidden;
}

.screen-turn-effect.off {
    visibility: hidden;
}

