.form {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}
.message {
    padding: 5px 20px;
    max-width: 600px;
    margin: 15px;
    font-size: 16px;
    line-height: 18px;
    border-radius: 20px;
    border: 2px solid white;
}

.error {
    background-color: rgba(239, 0, 0, 0.5);
}

.notify {
    background-color: rgba(0, 0, 50, 0.5);
}

.login-buttons {
    display: flex;
    flex-direction: row;
    width: 200px;
    justify-content: space-evenly;
}

.password-reset {
    margin-top: 15px;
    font-size: 20px;
    text-decoration: none;
    border: none;
    padding: 5px;
    border-radius: 5px;
}