.podium {
    width: 100px;
    height: 200px;
    position: relative;
    animation: rotate 5s linear infinite;
    transform-style: preserve-3d;
    margin: 0 50px;
}

.podium > h2 {
    margin: 0;
    position: absolute;
    top: -50px;
    right: 50%;
    transform: translateX(50%);
}

.podium.second {
    height: 125px
}

.podium.third {
    height: 75px
}

.podium .side {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    padding-top: 20px;
    color: #14fdce;
}

.podium .front {
    transform: rotateY(0deg) translateZ(50px);
    border: 2px dashed #14fdce;
    background: #383838;

}

.podium .left {
    transform: rotateY(90deg) translateZ(50px);
    border: 2px dashed #14fdce;
    background: #383838;
}


.podium .right {
    transform: rotateY(270deg) translateZ(50px);
    border: 2px dashed #14fdce;
    background: #383838;
}

.podium .back {
    transform: rotateY(180deg) translateZ(50px);
    border: 2px dashed #14fdce;
    background: #383838;
}


@keyframes rotate {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}