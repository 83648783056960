.brackets-page {
    margin-top: 40px;
}

.brackets-filter {
    padding: 20px 20px 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.bracket-name {
    border: 2px dashed #14fdce;
    width: 185px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 82px;
    margin: 20px 20px 20px 0;
}

.bracket-name:hover {
    background-color: grey;
}

.bracket-name.selected {
    background-color: black;
}

.bracket-header {
    text-align: center;
    margin: 0 0 20px 0;
    border-top: 3px solid grey;
    border-bottom: 3px solid grey;
}