.period {
    transition: opacity 1s ease;
    opacity: 0;
}
.period.one {
    transition-delay: .5s;
}

.period.two {
    transition-delay: 1s;
}

.period.flash {
    display: inline-block;
    animation: flash 1s infinite;
    animation-delay: 2s;
    transition-delay: 1.5s;
}

.period.on {
    opacity: 1;
}

@keyframes flash {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}