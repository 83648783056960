@keyframes animateWorld {
    0% {
        -webkit-transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg);
    }
    50% {
        -webkit-transform: rotateY(360deg) rotateX(180deg) rotateZ(180deg);
    }
    100% {
        -webkit-transform: rotateY(720deg) rotateX(0deg) rotateZ(360deg);
    }
}

@keyframes animateText {
    0%, 100% {
        -webkit-transform: translateZ(0);
    }
    50% {
        -webkit-transform: translateZ(-100px);
    }
}

.world {
    -webkit-perspective: 1000px;
}

.cube {
    margin: 100px auto;
    position: relative;
    width: 200px;
    height: 200px;
    -webkit-transform-style: preserve-3d;
    -webkit-animation-name: animateWorld;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
}

.circle {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px dashed #14fdce;
    border-radius: 50%;
    opacity: 0.8;
    background: rgba(255, 255, 255, 0);
    transform: scale(1.5)
}

.zero {
    -webkit-transform: rotateX(90deg) scale(1.5);
}

.two {
    -webkit-transform: rotateY(45deg) scale(1.5);
}

.three {
    -webkit-transform: rotateY(90deg) scale(1.5);
}

.four {
    -webkit-transform: rotateY(135deg) scale(1.5);
}

.five {
    width: 173px;
    height: 173px;
    margin: 14px;
    -webkit-transform: rotateX(90deg) translateZ(50px) scale(1.5);
}

.six {
    width: 173px;
    height: 173px;
    margin: 14px;
    -webkit-transform: rotateX(90deg) translateZ(-50px) scale(1.5);
}

.text-in-sphere {
    position: absolute;
    opacity: 90%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 200px;
    height: 200px;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}