.corner-container {
    padding: 0 20px;
    background:
            linear-gradient(to right, #14fdce 4px, transparent 4px) 0 0,
            linear-gradient(to right, #14fdce 4px, transparent 4px) 0 100%,
            linear-gradient(to left, #14fdce 4px, transparent 4px) 100% 0,
            linear-gradient(to left, #14fdce 4px, transparent 4px) 100% 100%,
            linear-gradient(to bottom, #14fdce 4px, transparent 4px) 0 0,
            linear-gradient(to bottom, #14fdce 4px, transparent 4px) 100% 0,
            linear-gradient(to top, #14fdce 4px, transparent 4px) 0 100%,
            linear-gradient(to top, #14fdce 4px, transparent 4px) 100% 100%;

    background-repeat: no-repeat;
    background-size: 15px 15px;
    display: flex;
    flex-direction: column;
    min-width: 40%;
}

.corner-container > h1 {
    text-align: center;
}

.heart-container {
    margin-top: 30px;
    justify-content: space-evenly;
    width: 100%;
    min-width: 300px;
    height: 50px;
    display: flex;
    flex-direction: row;
}

.heart-container > .pixelated-heart {
    margin-right: 75px;
}

.pixelated-heart.consumed {
    box-shadow:
            10px 00px #F22613,
            20px 00px #F22613,
            40px 00px #F22613,
            50px 00px #F22613,

            00px 10px #F22613,
            10px 10px #031e11,
            20px 10px #031e11,
            30px 10px #F22613,
            40px 10px #031e11,
            50px 10px #031e11,
            60px 10px #F22613,

            00px 20px #F22613,
            10px 20px #031e11,
            20px 20px #031e11,
            30px 20px #031e11,
            40px 20px #031e11,
            50px 20px #031e11,
            60px 20px #F22613,

            10px 30px #F22613,
            20px 30px #031e11,
            30px 30px #031e11,
            40px 30px #031e11,
            50px 30px #F22613,

            20px 40px #F22613,
            30px 40px #031e11,
            40px 40px #F22613,

            30px 50px #F22613;
}
