.high-scores-container {
    max-width: 80%;
}
.high-score-bar {
    background-color: rgba(128,128,128, 0.25);
    border: 2px dashed;
    height: 50px; /* Height of the bars */
    margin-bottom: 5px; /* Spacing between bars */
    transition: width 0.5s; /* Add a smooth transition effect for expanding bars */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.high-score-bar span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.bar-container {
    display: flex;
    flex-direction: row;
    align-items: center
}

.high-score-bar .square-container img {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    border-radius: 40px;
}

.bar-name {
}

.bar-score {
    padding-left: 10px;
}
