.bracket {
    position: relative;
}

.participant {
    width: 100%;
    padding: 0px 10px;
    color: white;
    background-color: rgba(128,128,128, 0.25);
    border: 2px dashed #14fdce;
    position: relative;
}

.participant-image-container > p {
    margin: 0 10px;
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.participant-image-container {
    flex-direction: row;
    position: relative;
    height: 54px;
    overflow: hidden;
    align-items: center;
    display: flex;
}

.participant-image-container img {
    min-width: 40px;
    width: 40px;
    height: 40px;
    object-fit: cover; /* Adjust as needed to control how the image is scaled */
}

.matchup {
    position: relative;
    width: 200px;
    margin-bottom: 35px;
}

.matchup:last-of-type {
    margin-bottom: 40px!important;
}

.matchup-winner {
    background-color: rgba(0,192,0, 0.45);
}

.matchup-score {
    position: absolute;
    left: -45px;
    top: -20px;
    font-size: 20px;
    text-align: center;
    width: 30px;
}

.matchup-container-0 {
    position: absolute;
}

.matchup-container-1 {
    position: absolute;
    left: 300px;
    top: 75px;
}

.matchup-container-1 .matchup {
    margin-bottom: 184px;
}

.matchup-container-1 .matchup-merger-bottom,
.matchup-container-1 .matchup-merger-top {
    height: 149px;
}

.matchup-container-2 {
    left: 600px;
    position: absolute;
    top: 223px;
}

.matchup-container-2 .matchup {
    margin-bottom: 482px;
}

.matchup-container-2 .matchup-merger-bottom,
.matchup-container-2 .matchup-merger-top {
    height: 298px;
}

.matchup-container-2 .matchup-merger-bottom {
    top: -242px;
}

.matchup-container-3 {
    left: 900px;
    position: absolute;
    top: 521px;
}

.matchup-merger-line-back {
    position: absolute;
    top: calc(50% - 2px);
    left: -51px;
    height: 1px;
    background-color: grey;
    width: 50px;
}
.matchup-merger-top {
    position: absolute;
    width: 50px;
    height: 75px;
    right: -50px;
    top: 55px;
    background-color: transparent;
    border: 1px solid grey;
    border-left: none;
    border-bottom: none;
}

.matchup-merger-bottom {
    position: absolute;
    width: 50px;
    height: 150px;
    right: -50px;
    top: -94px;
    background-color: transparent;
    border: 1px solid grey;
    border-left: none;
    border-top: none;
}

.bracket-champ {
    position: absolute;
    top: -125px;
}

.bracket-label {
    position: absolute;
    top: -70px;
    font-size: 35px;
    text-align: center;
    width: 100%;
}