.confirmation {
    border-radius: 10px;
    padding: 25px;
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translateX(50%) translateY(-50%);
    background-color: #0c7b46;
    z-index: 5;
    text-align: center;
}

.confirmation-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.confirmation-background {
    position: fixed;
    background-color: black;
    height: 95%;
    width: 96%;
    top: 3%;
    left: 3%;
    border-radius: 2rem;
    z-index: 4;
}