.coins-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 75px 0;
    position: relative;
    z-index: 3;
}

.coins-container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coins-container > div > button {
    width: 80px;
    height: 40px;
}

.coins {
    display: flex;
    height: 100px;
    margin-bottom: 35px;
    align-items: baseline;
}

.coins > .coin {
    margin: 100px 95px 0 0;
    top: -75px;
}

.coins > p {
    margin: 0 10px 0 0;
    font-size: 30px;
}

#coin-amount {
    font-size: 60px;
}

.coin {
    position: relative;
    top: -10px;
    left: -10px;
    animation: x 1s infinite;
    -webkit-animation: x 1s infinite;
    -moz-animation: x 1s infinite;
    -o-animation: x 1s infinite;
    transform: scale(.5);
    margin: 10px 25px;
}

.smaller-coin {
    top: -32px;
    left: -35px;
    transform: scale(.3);
}

@keyframes x {
    0%,
    16.7% {
        box-shadow: 80px 30px 0 0 rgba(0, 0, 0, 1), 90px 30px 0 0 rgba(0, 0, 0, 1),
        100px 30px 0 0 rgba(0, 0, 0, 1), 110px 30px 0 0 rgba(0, 0, 0, 1),
        120px 30px 0 0 rgba(0, 0, 0, 1), 130px 30px 0 0 rgba(0, 0, 0, 1),
        60px 40px 0 0 rgba(0, 0, 0, 1), 70px 40px 0 0 rgba(0, 0, 0, 1),
        80px 40px 0 0 rgba(177, 96, 0, 1), 90px 40px 0 0 rgba(177, 96, 0, 1),
        100px 40px 0 0 rgba(197, 107, 0, 1), 110px 40px 0 0 rgba(197, 107, 0, 1),
        120px 40px 0 0 rgba(210, 113, 0, 1), 130px 40px 0 0 rgba(210, 113, 0, 1),
        140px 40px 0 0 rgba(0, 0, 0, 1), 150px 40px 0 0 rgba(0, 0, 0, 1),
        50px 50px 0 0 rgba(0, 0, 0, 1), 60px 50px 0 0 rgba(177, 96, 0, 1),
        70px 50px 0 0 rgba(216, 130, 4, 1), 80px 50px 0 0 rgba(255, 155, 34, 1),
        90px 50px 0 0 rgba(255, 155, 34, 1), 100px 50px 0 0 rgba(255, 155, 34, 1),
        110px 50px 0 0 rgba(255, 155, 34, 1), 120px 50px 0 0 rgba(255, 155, 34, 1),
        130px 50px 0 0 rgba(255, 155, 34, 1), 140px 50px 0 0 rgba(255, 155, 34, 1),
        150px 50px 0 0 rgba(255, 182, 93, 1), 160px 50px 0 0 rgba(0, 0, 0, 1),
        40px 60px 0 0 rgba(0, 0, 0, 1), 50px 60px 0 0 rgba(177, 96, 0, 1),
        60px 60px 0 0 rgba(216, 130, 4, 1), 70px 60px 0 0 rgba(255, 155, 34, 1),
        80px 60px 0 0 rgba(255, 182, 93, 1), 90px 60px 0 0 rgba(255, 255, 255, 1),
        100px 60px 0 0 rgba(208, 114, 0, 1), 110px 60px 0 0 rgba(255, 255, 255, 1),
        120px 60px 0 0 rgba(208, 114, 0, 1), 130px 60px 0 0 rgba(255, 144, 0, 1),
        140px 60px 0 0 rgba(255, 155, 34, 1), 150px 60px 0 0 rgba(255, 155, 34, 1),
        160px 60px 0 0 rgba(255, 182, 93, 1), 170px 60px 0 0 rgba(0, 0, 0, 1),
        40px 70px 0 0 rgba(0, 0, 0, 1), 50px 70px 0 0 rgba(177, 96, 0, 1),
        60px 70px 0 0 rgba(255, 155, 34, 1), 70px 70px 0 0 rgba(255, 155, 34, 1),
        80px 70px 0 0 rgba(255, 255, 255, 1), 90px 70px 0 0 rgba(255, 255, 255, 1),
        100px 70px 0 0 rgba(255, 255, 255, 1),
        110px 70px 0 0 rgba(255, 255, 255, 1),
        120px 70px 0 0 rgba(255, 255, 255, 1), 130px 70px 0 0 rgba(255, 144, 0, 1),
        140px 70px 0 0 rgba(255, 144, 0, 1), 150px 70px 0 0 rgba(255, 144, 0, 1),
        160px 70px 0 0 rgba(255, 182, 93, 1), 170px 70px 0 0 rgba(0, 0, 0, 1),
        30px 80px 0 0 rgba(0, 0, 0, 1), 40px 80px 0 0 rgba(177, 96, 0, 1),
        50px 80px 0 0 rgba(243, 146, 6, 1), 60px 80px 0 0 rgba(255, 155, 34, 1),
        70px 80px 0 0 rgba(255, 138, 0, 1), 80px 80px 0 0 rgba(255, 255, 255, 1),
        90px 80px 0 0 rgba(212, 115, 0, 1), 100px 80px 0 0 rgba(212, 115, 0, 1),
        110px 80px 0 0 rgba(212, 115, 0, 1), 120px 80px 0 0 rgba(212, 115, 0, 1),
        130px 80px 0 0 rgba(255, 255, 255, 1), 140px 80px 0 0 rgba(208, 114, 0, 1),
        150px 80px 0 0 rgba(255, 144, 0, 1), 160px 80px 0 0 rgba(255, 155, 34, 1),
        170px 80px 0 0 rgba(255, 182, 93, 1), 180px 80px 0 0 rgba(0, 0, 0, 1),
        30px 90px 0 0 rgba(0, 0, 0, 1), 40px 90px 0 0 rgba(177, 96, 0, 1),
        50px 90px 0 0 rgba(243, 146, 6, 1), 60px 90px 0 0 rgba(255, 155, 34, 1),
        70px 90px 0 0 rgba(255, 138, 0, 1), 80px 90px 0 0 rgba(255, 255, 255, 1),
        90px 90px 0 0 rgba(208, 114, 0, 1), 100px 90px 0 0 rgba(255, 152, 0, 1),
        110px 90px 0 0 rgba(255, 152, 0, 1), 120px 90px 0 0 rgba(255, 144, 0, 1),
        130px 90px 0 0 rgba(255, 255, 255, 1), 140px 90px 0 0 rgba(208, 114, 0, 1),
        150px 90px 0 0 rgba(255, 144, 0, 1), 160px 90px 0 0 rgba(255, 155, 34, 1),
        170px 90px 0 0 rgba(255, 182, 93, 1), 180px 90px 0 0 rgba(0, 0, 0, 1),
        30px 100px 0 0 rgba(0, 0, 0, 1), 40px 100px 0 0 rgba(177, 96, 0, 1),
        50px 100px 0 0 rgba(243, 146, 6, 1), 60px 100px 0 0 rgba(255, 155, 34, 1),
        70px 100px 0 0 rgba(255, 138, 0, 1), 80px 100px 0 0 rgba(255, 255, 255, 1),
        90px 100px 0 0 rgba(255, 255, 255, 1),
        100px 100px 0 0 rgba(255, 255, 255, 1),
        110px 100px 0 0 rgba(255, 255, 255, 1),
        120px 100px 0 0 rgba(255, 255, 255, 1),
        130px 100px 0 0 rgba(201, 109, 0, 1), 140px 100px 0 0 rgba(255, 139, 0, 1),
        150px 100px 0 0 rgba(255, 144, 0, 1),
        160px 100px 0 0 rgba(255, 155, 34, 1),
        170px 100px 0 0 rgba(255, 182, 93, 1), 180px 100px 0 0 rgba(0, 0, 0, 1),
        30px 110px 0 0 rgba(0, 0, 0, 1), 40px 110px 0 0 rgba(177, 96, 0, 1),
        50px 110px 0 0 rgba(243, 146, 6, 1), 60px 110px 0 0 rgba(255, 155, 34, 1),
        70px 110px 0 0 rgba(255, 138, 0, 1), 80px 110px 0 0 rgba(255, 255, 255, 1),
        90px 110px 0 0 rgba(212, 115, 0, 1), 100px 110px 0 0 rgba(212, 115, 0, 1),
        110px 110px 0 0 rgba(212, 115, 0, 1), 120px 110px 0 0 rgba(212, 115, 0, 1),
        130px 110px 0 0 rgba(255, 255, 255, 1),
        140px 110px 0 0 rgba(208, 114, 0, 1), 150px 110px 0 0 rgba(255, 144, 0, 1),
        160px 110px 0 0 rgba(255, 155, 34, 1),
        170px 110px 0 0 rgba(255, 182, 93, 1), 180px 110px 0 0 rgba(0, 0, 0, 1),
        30px 120px 0 0 rgba(0, 0, 0, 1), 40px 120px 0 0 rgba(177, 96, 0, 1),
        50px 120px 0 0 rgba(243, 146, 6, 1), 60px 120px 0 0 rgba(255, 155, 34, 1),
        70px 120px 0 0 rgba(255, 138, 0, 1), 80px 120px 0 0 rgba(255, 255, 255, 1),
        90px 120px 0 0 rgba(208, 114, 0, 1), 100px 120px 0 0 rgba(255, 152, 0, 1),
        110px 120px 0 0 rgba(255, 152, 0, 1), 120px 120px 0 0 rgba(255, 144, 0, 1),
        130px 120px 0 0 rgba(255, 255, 255, 1),
        140px 120px 0 0 rgba(208, 114, 0, 1), 150px 120px 0 0 rgba(255, 144, 0, 1),
        160px 120px 0 0 rgba(255, 155, 34, 1),
        170px 120px 0 0 rgba(255, 182, 93, 1), 180px 120px 0 0 rgba(0, 0, 0, 1),
        30px 130px 0 0 rgba(0, 0, 0, 1), 40px 130px 0 0 rgba(177, 96, 0, 1),
        50px 130px 0 0 rgba(243, 146, 6, 1), 60px 130px 0 0 rgba(255, 155, 34, 1),
        70px 130px 0 0 rgba(255, 138, 0, 1), 80px 130px 0 0 rgba(255, 255, 255, 1),
        90px 130px 0 0 rgba(255, 255, 255, 1),
        100px 130px 0 0 rgba(255, 255, 255, 1),
        110px 130px 0 0 rgba(255, 255, 255, 1),
        120px 130px 0 0 rgba(255, 255, 255, 1),
        130px 130px 0 0 rgba(208, 114, 0, 1), 140px 130px 0 0 rgba(255, 139, 0, 1),
        150px 130px 0 0 rgba(255, 144, 0, 1),
        160px 130px 0 0 rgba(255, 155, 34, 1),
        170px 130px 0 0 rgba(255, 182, 93, 1), 180px 130px 0 0 rgba(0, 0, 0, 1),
        40px 140px 0 0 rgba(0, 0, 0, 1), 50px 140px 0 0 rgba(216, 130, 4, 1),
        60px 140px 0 0 rgba(255, 155, 34, 1), 70px 140px 0 0 rgba(255, 144, 0, 1),
        80px 140px 0 0 rgba(255, 139, 0, 1), 90px 140px 0 0 rgba(255, 255, 255, 1),
        100px 140px 0 0 rgba(189, 103, 0, 1),
        110px 140px 0 0 rgba(255, 255, 255, 1),
        120px 140px 0 0 rgba(212, 115, 0, 1), 130px 140px 0 0 rgba(255, 139, 0, 1),
        140px 140px 0 0 rgba(247, 144, 2, 1),
        150px 140px 0 0 rgba(255, 155, 34, 1),
        160px 140px 0 0 rgba(255, 174, 77, 1), 170px 140px 0 0 rgba(0, 0, 0, 1),
        40px 150px 0 0 rgba(0, 0, 0, 1), 50px 150px 0 0 rgba(177, 96, 0, 1),
        60px 150px 0 0 rgba(197, 118, 2, 1), 70px 150px 0 0 rgba(255, 155, 34, 1),
        80px 150px 0 0 rgba(255, 138, 0, 1), 90px 150px 0 0 rgba(255, 144, 0, 1),
        100px 150px 0 0 rgba(255, 139, 0, 1), 110px 150px 0 0 rgba(255, 144, 0, 1),
        120px 150px 0 0 rgba(255, 139, 0, 1),
        130px 150px 0 0 rgba(255, 155, 34, 1),
        140px 150px 0 0 rgba(255, 155, 34, 1),
        150px 150px 0 0 rgba(255, 174, 77, 1),
        160px 150px 0 0 rgba(255, 182, 93, 1), 170px 150px 0 0 rgba(0, 0, 0, 1),
        50px 160px 0 0 rgba(0, 0, 0, 1), 60px 160px 0 0 rgba(177, 96, 0, 1),
        70px 160px 0 0 rgba(208, 114, 0, 1), 80px 160px 0 0 rgba(255, 155, 34, 1),
        90px 160px 0 0 rgba(255, 155, 34, 1),
        100px 160px 0 0 rgba(255, 155, 34, 1),
        110px 160px 0 0 rgba(255, 155, 34, 1),
        120px 160px 0 0 rgba(255, 155, 34, 1),
        130px 160px 0 0 rgba(255, 174, 77, 1),
        140px 160px 0 0 rgba(255, 182, 93, 1),
        150px 160px 0 0 rgba(255, 182, 93, 1), 160px 160px 0 0 rgba(0, 0, 0, 1),
        60px 170px 0 0 rgba(0, 0, 0, 1), 70px 170px 0 0 rgba(0, 0, 0, 1),
        80px 170px 0 0 rgba(208, 114, 0, 1), 90px 170px 0 0 rgba(255, 182, 93, 1),
        100px 170px 0 0 rgba(255, 174, 77, 1),
        110px 170px 0 0 rgba(255, 174, 77, 1),
        120px 170px 0 0 rgba(255, 182, 93, 1),
        130px 170px 0 0 rgba(255, 182, 93, 1), 140px 170px 0 0 rgba(0, 0, 0, 1),
        150px 170px 0 0 rgba(0, 0, 0, 1), 80px 180px 0 0 rgba(0, 0, 0, 1),
        90px 180px 0 0 rgba(0, 0, 0, 1), 100px 180px 0 0 rgba(0, 0, 0, 1),
        110px 180px 0 0 rgba(0, 0, 0, 1), 120px 180px 0 0 rgba(0, 0, 0, 1),
        130px 180px 0 0 rgba(0, 0, 0, 1);
        height: 10px;
        width: 10px;
    }
    16.71%,
    28.6% {
        box-shadow: 80px 30px 0 0 rgba(0, 0, 0, 1), 90px 30px 0 0 rgba(0, 0, 0, 1),
        100px 30px 0 0 rgba(0, 0, 0, 1), 110px 30px 0 0 rgba(0, 0, 0, 1),
        120px 30px 0 0 rgba(0, 0, 0, 1), 130px 30px 0 0 rgba(0, 0, 0, 1),
        60px 40px 0 0 rgba(0, 0, 0, 1), 70px 40px 0 0 rgba(0, 0, 0, 1),
        80px 40px 0 0 rgba(177, 96, 0, 1), 90px 40px 0 0 rgba(177, 96, 0, 1),
        100px 40px 0 0 rgba(197, 107, 0, 1), 110px 40px 0 0 rgba(197, 107, 0, 1),
        120px 40px 0 0 rgba(210, 113, 0, 1), 130px 40px 0 0 rgba(210, 113, 0, 1),
        140px 40px 0 0 rgba(0, 0, 0, 1), 150px 40px 0 0 rgba(0, 0, 0, 1),
        50px 50px 0 0 rgba(0, 0, 0, 1), 60px 50px 0 0 rgba(177, 96, 0, 1),
        70px 50px 0 0 rgba(216, 130, 4, 1), 80px 50px 0 0 rgba(255, 155, 34, 1),
        90px 50px 0 0 rgba(255, 155, 34, 1), 100px 50px 0 0 rgba(255, 155, 34, 1),
        110px 50px 0 0 rgba(255, 155, 34, 1), 120px 50px 0 0 rgba(255, 155, 34, 1),
        130px 50px 0 0 rgba(255, 155, 34, 1), 140px 50px 0 0 rgba(255, 155, 34, 1),
        150px 50px 0 0 rgba(255, 182, 93, 1), 160px 50px 0 0 rgba(0, 0, 0, 1),
        40px 60px 0 0 rgba(0, 0, 0, 1), 50px 60px 0 0 rgba(177, 96, 0, 1),
        60px 60px 0 0 rgba(216, 130, 4, 1), 70px 60px 0 0 rgba(255, 155, 34, 1),
        80px 60px 0 0 rgba(255, 182, 93, 1), 90px 60px 0 0 rgba(255, 255, 255, 1),
        100px 60px 0 0 rgba(208, 114, 0, 1), 110px 60px 0 0 rgba(255, 255, 255, 1),
        120px 60px 0 0 rgba(208, 114, 0, 1), 130px 60px 0 0 rgba(255, 144, 0, 1),
        140px 60px 0 0 rgba(255, 155, 34, 1), 150px 60px 0 0 rgba(255, 155, 34, 1),
        160px 60px 0 0 rgba(255, 182, 93, 1), 170px 60px 0 0 rgba(0, 0, 0, 1),
        40px 70px 0 0 rgba(0, 0, 0, 1), 50px 70px 0 0 rgba(177, 96, 0, 1),
        60px 70px 0 0 rgba(255, 155, 34, 1), 70px 70px 0 0 rgba(255, 155, 34, 1),
        80px 70px 0 0 rgba(255, 255, 255, 1), 90px 70px 0 0 rgba(255, 255, 255, 1),
        100px 70px 0 0 rgba(255, 255, 255, 1),
        110px 70px 0 0 rgba(255, 255, 255, 1),
        120px 70px 0 0 rgba(255, 255, 255, 1), 130px 70px 0 0 rgba(255, 144, 0, 1),
        140px 70px 0 0 rgba(255, 144, 0, 1), 150px 70px 0 0 rgba(255, 144, 0, 1),
        160px 70px 0 0 rgba(255, 182, 93, 1), 170px 70px 0 0 rgba(0, 0, 0, 1),
        30px 80px 0 0 rgba(0, 0, 0, 1), 40px 80px 0 0 rgba(177, 96, 0, 1),
        50px 80px 0 0 rgba(243, 146, 6, 1), 60px 80px 0 0 rgba(255, 155, 34, 1),
        70px 80px 0 0 rgba(255, 138, 0, 1), 80px 80px 0 0 rgba(255, 255, 255, 1),
        90px 80px 0 0 rgba(212, 115, 0, 1), 100px 80px 0 0 rgba(212, 115, 0, 1),
        110px 80px 0 0 rgba(212, 115, 0, 1), 120px 80px 0 0 rgba(212, 115, 0, 1),
        130px 80px 0 0 rgba(255, 255, 255, 1), 140px 80px 0 0 rgba(208, 114, 0, 1),
        150px 80px 0 0 rgba(255, 144, 0, 1), 160px 80px 0 0 rgba(255, 155, 34, 1),
        170px 80px 0 0 rgba(255, 182, 93, 1), 180px 80px 0 0 rgba(0, 0, 0, 1),
        30px 90px 0 0 rgba(0, 0, 0, 1), 40px 90px 0 0 rgba(177, 96, 0, 1),
        50px 90px 0 0 rgba(243, 146, 6, 1), 60px 90px 0 0 rgba(255, 155, 34, 1),
        70px 90px 0 0 rgba(255, 138, 0, 1), 80px 90px 0 0 rgba(255, 255, 255, 1),
        90px 90px 0 0 rgba(208, 114, 0, 1), 100px 90px 0 0 rgba(255, 152, 0, 1),
        110px 90px 0 0 rgba(255, 152, 0, 1), 120px 90px 0 0 rgba(255, 144, 0, 1),
        130px 90px 0 0 rgba(255, 255, 255, 1), 140px 90px 0 0 rgba(208, 114, 0, 1),
        150px 90px 0 0 rgba(255, 144, 0, 1), 160px 90px 0 0 rgba(255, 155, 34, 1),
        170px 90px 0 0 rgba(255, 182, 93, 1), 180px 90px 0 0 rgba(0, 0, 0, 1),
        30px 100px 0 0 rgba(0, 0, 0, 1), 40px 100px 0 0 rgba(177, 96, 0, 1),
        50px 100px 0 0 rgba(243, 146, 6, 1), 60px 100px 0 0 rgba(255, 155, 34, 1),
        70px 100px 0 0 rgba(255, 138, 0, 1), 80px 100px 0 0 rgba(255, 255, 255, 1),
        90px 100px 0 0 rgba(255, 255, 255, 1),
        100px 100px 0 0 rgba(255, 255, 255, 1),
        110px 100px 0 0 rgba(255, 255, 255, 1),
        120px 100px 0 0 rgba(255, 255, 255, 1),
        130px 100px 0 0 rgba(201, 109, 0, 1), 140px 100px 0 0 rgba(255, 139, 0, 1),
        150px 100px 0 0 rgba(255, 144, 0, 1),
        160px 100px 0 0 rgba(255, 155, 34, 1),
        170px 100px 0 0 rgba(255, 182, 93, 1), 180px 100px 0 0 rgba(0, 0, 0, 1),
        30px 110px 0 0 rgba(0, 0, 0, 1), 40px 110px 0 0 rgba(177, 96, 0, 1),
        50px 110px 0 0 rgba(243, 146, 6, 1), 60px 110px 0 0 rgba(255, 155, 34, 1),
        70px 110px 0 0 rgba(255, 138, 0, 1), 80px 110px 0 0 rgba(255, 255, 255, 1),
        90px 110px 0 0 rgba(212, 115, 0, 1), 100px 110px 0 0 rgba(212, 115, 0, 1),
        110px 110px 0 0 rgba(212, 115, 0, 1), 120px 110px 0 0 rgba(212, 115, 0, 1),
        130px 110px 0 0 rgba(255, 255, 255, 1),
        140px 110px 0 0 rgba(208, 114, 0, 1), 150px 110px 0 0 rgba(255, 144, 0, 1),
        160px 110px 0 0 rgba(255, 155, 34, 1),
        170px 110px 0 0 rgba(255, 182, 93, 1), 180px 110px 0 0 rgba(0, 0, 0, 1),
        30px 120px 0 0 rgba(0, 0, 0, 1), 40px 120px 0 0 rgba(177, 96, 0, 1),
        50px 120px 0 0 rgba(243, 146, 6, 1), 60px 120px 0 0 rgba(255, 155, 34, 1),
        70px 120px 0 0 rgba(255, 138, 0, 1), 80px 120px 0 0 rgba(255, 255, 255, 1),
        90px 120px 0 0 rgba(208, 114, 0, 1), 100px 120px 0 0 rgba(255, 152, 0, 1),
        110px 120px 0 0 rgba(255, 152, 0, 1), 120px 120px 0 0 rgba(255, 144, 0, 1),
        130px 120px 0 0 rgba(255, 255, 255, 1),
        140px 120px 0 0 rgba(208, 114, 0, 1), 150px 120px 0 0 rgba(255, 144, 0, 1),
        160px 120px 0 0 rgba(255, 155, 34, 1),
        170px 120px 0 0 rgba(255, 182, 93, 1), 180px 120px 0 0 rgba(0, 0, 0, 1),
        30px 130px 0 0 rgba(0, 0, 0, 1), 40px 130px 0 0 rgba(177, 96, 0, 1),
        50px 130px 0 0 rgba(243, 146, 6, 1), 60px 130px 0 0 rgba(255, 155, 34, 1),
        70px 130px 0 0 rgba(255, 138, 0, 1), 80px 130px 0 0 rgba(255, 255, 255, 1),
        90px 130px 0 0 rgba(255, 255, 255, 1),
        100px 130px 0 0 rgba(255, 255, 255, 1),
        110px 130px 0 0 rgba(255, 255, 255, 1),
        120px 130px 0 0 rgba(255, 255, 255, 1),
        130px 130px 0 0 rgba(208, 114, 0, 1), 140px 130px 0 0 rgba(255, 139, 0, 1),
        150px 130px 0 0 rgba(255, 144, 0, 1),
        160px 130px 0 0 rgba(255, 155, 34, 1),
        170px 130px 0 0 rgba(255, 182, 93, 1), 180px 130px 0 0 rgba(0, 0, 0, 1),
        40px 140px 0 0 rgba(0, 0, 0, 1), 50px 140px 0 0 rgba(216, 130, 4, 1),
        60px 140px 0 0 rgba(255, 155, 34, 1), 70px 140px 0 0 rgba(255, 144, 0, 1),
        80px 140px 0 0 rgba(255, 139, 0, 1), 90px 140px 0 0 rgba(255, 255, 255, 1),
        100px 140px 0 0 rgba(189, 103, 0, 1),
        110px 140px 0 0 rgba(255, 255, 255, 1),
        120px 140px 0 0 rgba(212, 115, 0, 1), 130px 140px 0 0 rgba(255, 139, 0, 1),
        140px 140px 0 0 rgba(247, 144, 2, 1),
        150px 140px 0 0 rgba(255, 155, 34, 1),
        160px 140px 0 0 rgba(255, 174, 77, 1), 170px 140px 0 0 rgba(0, 0, 0, 1),
        40px 150px 0 0 rgba(0, 0, 0, 1), 50px 150px 0 0 rgba(177, 96, 0, 1),
        60px 150px 0 0 rgba(197, 118, 2, 1), 70px 150px 0 0 rgba(255, 155, 34, 1),
        80px 150px 0 0 rgba(255, 138, 0, 1), 90px 150px 0 0 rgba(255, 144, 0, 1),
        100px 150px 0 0 rgba(255, 139, 0, 1), 110px 150px 0 0 rgba(255, 144, 0, 1),
        120px 150px 0 0 rgba(255, 139, 0, 1),
        130px 150px 0 0 rgba(255, 155, 34, 1),
        140px 150px 0 0 rgba(255, 155, 34, 1),
        150px 150px 0 0 rgba(255, 174, 77, 1),
        160px 150px 0 0 rgba(255, 182, 93, 1), 170px 150px 0 0 rgba(0, 0, 0, 1),
        50px 160px 0 0 rgba(0, 0, 0, 1), 60px 160px 0 0 rgba(177, 96, 0, 1),
        70px 160px 0 0 rgba(208, 114, 0, 1), 80px 160px 0 0 rgba(255, 155, 34, 1),
        90px 160px 0 0 rgba(255, 155, 34, 1),
        100px 160px 0 0 rgba(255, 155, 34, 1),
        110px 160px 0 0 rgba(255, 155, 34, 1),
        120px 160px 0 0 rgba(255, 155, 34, 1),
        130px 160px 0 0 rgba(255, 174, 77, 1),
        140px 160px 0 0 rgba(255, 182, 93, 1),
        150px 160px 0 0 rgba(255, 182, 93, 1), 160px 160px 0 0 rgba(0, 0, 0, 1),
        60px 170px 0 0 rgba(0, 0, 0, 1), 70px 170px 0 0 rgba(0, 0, 0, 1),
        80px 170px 0 0 rgba(208, 114, 0, 1), 90px 170px 0 0 rgba(255, 182, 93, 1),
        100px 170px 0 0 rgba(255, 174, 77, 1),
        110px 170px 0 0 rgba(255, 174, 77, 1),
        120px 170px 0 0 rgba(255, 182, 93, 1),
        130px 170px 0 0 rgba(255, 182, 93, 1), 140px 170px 0 0 rgba(0, 0, 0, 1),
        150px 170px 0 0 rgba(0, 0, 0, 1), 80px 180px 0 0 rgba(0, 0, 0, 1),
        90px 180px 0 0 rgba(0, 0, 0, 1), 100px 180px 0 0 rgba(0, 0, 0, 1),
        110px 180px 0 0 rgba(0, 0, 0, 1), 120px 180px 0 0 rgba(0, 0, 0, 1),
        130px 180px 0 0 rgba(0, 0, 0, 1);
        height: 10px;
        width: 10px;
    }
    28.610000000000003%,
    42.9% {
        box-shadow: 80px 30px 0 0 rgba(0, 0, 0, 1), 90px 30px 0 0 rgba(0, 0, 0, 1),
        100px 30px 0 0 rgba(0, 0, 0, 1), 110px 30px 0 0 rgba(0, 0, 0, 1),
        120px 30px 0 0 rgba(0, 0, 0, 1), 130px 30px 0 0 rgba(0, 0, 0, 1),
        70px 40px 0 0 rgba(0, 0, 0, 1), 80px 40px 0 0 rgba(177, 96, 0, 1),
        90px 40px 0 0 rgba(177, 96, 0, 1), 100px 40px 0 0 rgba(197, 107, 0, 1),
        110px 40px 0 0 rgba(197, 107, 0, 1), 120px 40px 0 0 rgba(210, 113, 0, 1),
        130px 40px 0 0 rgba(210, 113, 0, 1), 140px 40px 0 0 rgba(0, 0, 0, 1),
        60px 50px 0 0 rgba(0, 0, 0, 1), 70px 50px 0 0 rgba(216, 130, 4, 1),
        80px 50px 0 0 rgba(255, 155, 34, 1), 90px 50px 0 0 rgba(255, 155, 34, 1),
        100px 50px 0 0 rgba(255, 155, 34, 1), 110px 50px 0 0 rgba(255, 155, 34, 1),
        120px 50px 0 0 rgba(226, 124, 1, 1), 130px 50px 0 0 rgba(226, 124, 1, 1),
        140px 50px 0 0 rgba(226, 124, 1, 1), 150px 50px 0 0 rgba(0, 0, 0, 1),
        50px 60px 0 0 rgba(0, 0, 0, 1), 60px 60px 0 0 rgba(216, 130, 4, 1),
        70px 60px 0 0 rgba(255, 155, 34, 1), 80px 60px 0 0 rgba(255, 182, 93, 1),
        90px 60px 0 0 rgba(255, 139, 0, 1), 100px 60px 0 0 rgba(255, 255, 255, 1),
        110px 60px 0 0 rgba(255, 139, 0, 1), 120px 60px 0 0 rgba(226, 226, 226, 1),
        130px 60px 0 0 rgba(255, 144, 0, 1), 140px 60px 0 0 rgba(226, 124, 1, 1),
        150px 60px 0 0 rgba(226, 124, 1, 1), 160px 60px 0 0 rgba(0, 0, 0, 1),
        50px 70px 0 0 rgba(0, 0, 0, 1), 60px 70px 0 0 rgba(255, 174, 77, 1),
        70px 70px 0 0 rgba(255, 155, 34, 1), 80px 70px 0 0 rgba(255, 139, 0, 1),
        90px 70px 0 0 rgba(255, 255, 255, 1),
        100px 70px 0 0 rgba(255, 255, 255, 1),
        110px 70px 0 0 rgba(239, 239, 239, 1),
        120px 70px 0 0 rgba(226, 226, 226, 1),
        130px 70px 0 0 rgba(226, 226, 226, 1), 140px 70px 0 0 rgba(226, 124, 1, 1),
        150px 70px 0 0 rgba(226, 124, 1, 1), 160px 70px 0 0 rgba(0, 0, 0, 1),
        40px 80px 0 0 rgba(0, 0, 0, 1), 50px 80px 0 0 rgba(255, 182, 93, 1),
        60px 80px 0 0 rgba(255, 174, 77, 1), 70px 80px 0 0 rgba(255, 138, 0, 1),
        80px 80px 0 0 rgba(255, 139, 0, 1), 90px 80px 0 0 rgba(255, 255, 255, 1),
        100px 80px 0 0 rgba(208, 114, 0, 1), 110px 80px 0 0 rgba(208, 114, 0, 1),
        120px 80px 0 0 rgba(208, 114, 0, 1), 130px 80px 0 0 rgba(208, 114, 0, 1),
        140px 80px 0 0 rgba(214, 212, 212, 1), 150px 80px 0 0 rgba(226, 124, 1, 1),
        160px 80px 0 0 rgba(226, 124, 1, 1), 170px 80px 0 0 rgba(0, 0, 0, 1),
        40px 90px 0 0 rgba(0, 0, 0, 1), 50px 90px 0 0 rgba(255, 182, 93, 1),
        60px 90px 0 0 rgba(255, 155, 34, 1), 70px 90px 0 0 rgba(255, 138, 0, 1),
        80px 90px 0 0 rgba(255, 139, 0, 1), 90px 90px 0 0 rgba(255, 255, 255, 1),
        100px 90px 0 0 rgba(193, 105, 3, 1), 110px 90px 0 0 rgba(255, 152, 0, 1),
        120px 90px 0 0 rgba(255, 144, 0, 1), 130px 90px 0 0 rgba(226, 124, 1, 1),
        140px 90px 0 0 rgba(214, 212, 212, 1), 150px 90px 0 0 rgba(226, 124, 1, 1),
        160px 90px 0 0 rgba(226, 124, 1, 1), 170px 90px 0 0 rgba(0, 0, 0, 1),
        40px 100px 0 0 rgba(0, 0, 0, 1), 50px 100px 0 0 rgba(255, 182, 93, 1),
        60px 100px 0 0 rgba(255, 155, 34, 1), 70px 100px 0 0 rgba(255, 138, 0, 1),
        80px 100px 0 0 rgba(255, 139, 0, 1), 90px 100px 0 0 rgba(255, 255, 255, 1),
        100px 100px 0 0 rgba(255, 255, 255, 1),
        110px 100px 0 0 rgba(239, 239, 239, 1),
        120px 100px 0 0 rgba(226, 226, 226, 1),
        130px 100px 0 0 rgba(226, 226, 226, 1),
        140px 100px 0 0 rgba(226, 124, 1, 1), 150px 100px 0 0 rgba(226, 124, 1, 1),
        160px 100px 0 0 rgba(222, 120, 3, 1), 170px 100px 0 0 rgba(0, 0, 0, 1),
        40px 110px 0 0 rgba(0, 0, 0, 1), 50px 110px 0 0 rgba(255, 182, 93, 1),
        60px 110px 0 0 rgba(255, 155, 34, 1), 70px 110px 0 0 rgba(255, 138, 0, 1),
        80px 110px 0 0 rgba(255, 139, 0, 1), 90px 110px 0 0 rgba(255, 255, 255, 1),
        100px 110px 0 0 rgba(208, 114, 0, 1), 110px 110px 0 0 rgba(208, 114, 0, 1),
        120px 110px 0 0 rgba(208, 114, 0, 1), 130px 110px 0 0 rgba(208, 114, 0, 1),
        140px 110px 0 0 rgba(214, 212, 212, 1),
        150px 110px 0 0 rgba(226, 124, 1, 1), 160px 110px 0 0 rgba(208, 114, 0, 1),
        170px 110px 0 0 rgba(0, 0, 0, 1), 40px 120px 0 0 rgba(0, 0, 0, 1),
        50px 120px 0 0 rgba(255, 182, 93, 1), 60px 120px 0 0 rgba(255, 155, 34, 1),
        70px 120px 0 0 rgba(255, 138, 0, 1), 80px 120px 0 0 rgba(255, 139, 0, 1),
        90px 120px 0 0 rgba(255, 255, 255, 1),
        100px 120px 0 0 rgba(193, 105, 3, 1), 110px 120px 0 0 rgba(255, 152, 0, 1),
        120px 120px 0 0 rgba(255, 144, 0, 1), 130px 120px 0 0 rgba(226, 124, 1, 1),
        140px 120px 0 0 rgba(214, 212, 212, 1),
        150px 120px 0 0 rgba(226, 124, 1, 1), 160px 120px 0 0 rgba(208, 114, 0, 1),
        170px 120px 0 0 rgba(0, 0, 0, 1), 40px 130px 0 0 rgba(0, 0, 0, 1),
        50px 130px 0 0 rgba(255, 182, 93, 1), 60px 130px 0 0 rgba(255, 174, 77, 1),
        70px 130px 0 0 rgba(255, 138, 0, 1), 80px 130px 0 0 rgba(255, 139, 0, 1),
        90px 130px 0 0 rgba(255, 255, 255, 1),
        100px 130px 0 0 rgba(255, 255, 255, 1),
        110px 130px 0 0 rgba(239, 239, 239, 1),
        120px 130px 0 0 rgba(226, 226, 226, 1),
        130px 130px 0 0 rgba(226, 226, 226, 1),
        140px 130px 0 0 rgba(226, 124, 1, 1), 150px 130px 0 0 rgba(226, 124, 1, 1),
        160px 130px 0 0 rgba(208, 114, 0, 1), 170px 130px 0 0 rgba(0, 0, 0, 1),
        50px 140px 0 0 rgba(0, 0, 0, 1), 60px 140px 0 0 rgba(255, 174, 77, 1),
        70px 140px 0 0 rgba(255, 144, 0, 1), 80px 140px 0 0 rgba(255, 139, 0, 1),
        90px 140px 0 0 rgba(255, 139, 0, 1),
        100px 140px 0 0 rgba(255, 255, 255, 1),
        110px 140px 0 0 rgba(208, 114, 0, 1),
        120px 140px 0 0 rgba(226, 226, 226, 1),
        130px 140px 0 0 rgba(255, 139, 0, 1), 140px 140px 0 0 rgba(226, 124, 1, 1),
        150px 140px 0 0 rgba(208, 114, 0, 1), 160px 140px 0 0 rgba(0, 0, 0, 1),
        50px 150px 0 0 rgba(0, 0, 0, 1), 60px 150px 0 0 rgba(197, 118, 2, 1),
        70px 150px 0 0 rgba(255, 174, 77, 1), 80px 150px 0 0 rgba(255, 138, 0, 1),
        90px 150px 0 0 rgba(255, 144, 0, 1), 100px 150px 0 0 rgba(255, 139, 0, 1),
        110px 150px 0 0 rgba(255, 144, 0, 1), 120px 150px 0 0 rgba(255, 139, 0, 1),
        130px 150px 0 0 rgba(226, 124, 1, 1), 140px 150px 0 0 rgba(226, 124, 1, 1),
        150px 150px 0 0 rgba(208, 114, 0, 1), 160px 150px 0 0 rgba(0, 0, 0, 1),
        60px 160px 0 0 rgba(0, 0, 0, 1), 70px 160px 0 0 rgba(208, 114, 0, 1),
        80px 160px 0 0 rgba(255, 155, 34, 1), 90px 160px 0 0 rgba(255, 155, 34, 1),
        100px 160px 0 0 rgba(255, 155, 34, 1),
        110px 160px 0 0 rgba(255, 155, 34, 1),
        120px 160px 0 0 rgba(226, 124, 1, 1), 130px 160px 0 0 rgba(226, 124, 1, 1),
        140px 160px 0 0 rgba(208, 114, 0, 1), 150px 160px 0 0 rgba(0, 0, 0, 1),
        70px 170px 0 0 rgba(0, 0, 0, 1), 80px 170px 0 0 rgba(208, 114, 0, 1),
        90px 170px 0 0 rgba(226, 124, 1, 1), 100px 170px 0 0 rgba(226, 124, 1, 1),
        110px 170px 0 0 rgba(226, 124, 1, 1), 120px 170px 0 0 rgba(208, 114, 0, 1),
        130px 170px 0 0 rgba(208, 114, 0, 1), 140px 170px 0 0 rgba(0, 0, 0, 1),
        80px 180px 0 0 rgba(0, 0, 0, 1), 90px 180px 0 0 rgba(0, 0, 0, 1),
        100px 180px 0 0 rgba(0, 0, 0, 1), 110px 180px 0 0 rgba(0, 0, 0, 1),
        120px 180px 0 0 rgba(0, 0, 0, 1), 130px 180px 0 0 rgba(0, 0, 0, 1);
        height: 10px;
        width: 10px;
    }
    42.91%,
    57.1% {
        box-shadow: 90px 30px 0 0 rgba(0, 0, 0, 1), 100px 30px 0 0 rgba(0, 0, 0, 1),
        110px 30px 0 0 rgba(0, 0, 0, 1), 120px 30px 0 0 rgba(0, 0, 0, 1),
        80px 40px 0 0 rgba(0, 0, 0, 1), 90px 40px 0 0 rgba(239, 129, 1, 1),
        100px 40px 0 0 rgba(239, 129, 1, 1), 110px 40px 0 0 rgba(239, 129, 1, 1),
        120px 40px 0 0 rgba(239, 129, 1, 1), 130px 40px 0 0 rgba(0, 0, 0, 1),
        80px 50px 0 0 rgba(0, 0, 0, 1), 90px 50px 0 0 rgba(239, 129, 1, 1),
        100px 50px 0 0 rgba(208, 114, 0, 1), 110px 50px 0 0 rgba(239, 129, 1, 1),
        120px 50px 0 0 rgba(239, 129, 1, 1), 130px 50px 0 0 rgba(0, 0, 0, 1),
        70px 60px 0 0 rgba(0, 0, 0, 1), 80px 60px 0 0 rgba(255, 174, 77, 1),
        90px 60px 0 0 rgba(208, 114, 0, 1), 100px 60px 0 0 rgba(255, 174, 77, 1),
        110px 60px 0 0 rgba(226, 226, 226, 1), 120px 60px 0 0 rgba(255, 139, 0, 1),
        130px 60px 0 0 rgba(255, 144, 0, 1), 140px 60px 0 0 rgba(0, 0, 0, 1),
        70px 70px 0 0 rgba(0, 0, 0, 1), 80px 70px 0 0 rgba(255, 174, 77, 1),
        90px 70px 0 0 rgba(208, 114, 0, 1), 100px 70px 0 0 rgba(255, 255, 255, 1),
        110px 70px 0 0 rgba(226, 226, 226, 1),
        120px 70px 0 0 rgba(214, 212, 212, 1),
        130px 70px 0 0 rgba(255, 182, 93, 1), 140px 70px 0 0 rgba(0, 0, 0, 1),
        70px 80px 0 0 rgba(0, 0, 0, 1), 80px 80px 0 0 rgba(255, 174, 77, 1),
        90px 80px 0 0 rgba(208, 114, 0, 1), 100px 80px 0 0 rgba(255, 255, 255, 1),
        110px 80px 0 0 rgba(208, 114, 0, 1), 120px 80px 0 0 rgba(208, 114, 0, 1),
        130px 80px 0 0 rgba(255, 139, 0, 1), 140px 80px 0 0 rgba(0, 0, 0, 1),
        70px 90px 0 0 rgba(0, 0, 0, 1), 80px 90px 0 0 rgba(255, 174, 77, 1),
        90px 90px 0 0 rgba(208, 114, 0, 1), 100px 90px 0 0 rgba(255, 255, 255, 1),
        110px 90px 0 0 rgba(255, 139, 0, 1), 120px 90px 0 0 rgba(255, 144, 0, 1),
        130px 90px 0 0 rgba(239, 129, 1, 1), 140px 90px 0 0 rgba(0, 0, 0, 1),
        70px 100px 0 0 rgba(0, 0, 0, 1), 80px 100px 0 0 rgba(255, 174, 77, 1),
        90px 100px 0 0 rgba(208, 114, 0, 1),
        100px 100px 0 0 rgba(255, 255, 255, 1),
        110px 100px 0 0 rgba(226, 226, 226, 1),
        120px 100px 0 0 rgba(214, 212, 212, 1),
        130px 100px 0 0 rgba(239, 129, 1, 1), 140px 100px 0 0 rgba(0, 0, 0, 1),
        70px 110px 0 0 rgba(0, 0, 0, 1), 80px 110px 0 0 rgba(255, 174, 77, 1),
        90px 110px 0 0 rgba(208, 114, 0, 1),
        100px 110px 0 0 rgba(255, 255, 255, 1),
        110px 110px 0 0 rgba(208, 114, 0, 1), 120px 110px 0 0 rgba(208, 114, 0, 1),
        130px 110px 0 0 rgba(239, 129, 1, 1), 140px 110px 0 0 rgba(0, 0, 0, 1),
        70px 120px 0 0 rgba(0, 0, 0, 1), 80px 120px 0 0 rgba(255, 174, 77, 1),
        90px 120px 0 0 rgba(208, 114, 0, 1),
        100px 120px 0 0 rgba(255, 255, 255, 1),
        110px 120px 0 0 rgba(255, 139, 0, 1), 120px 120px 0 0 rgba(255, 144, 0, 1),
        130px 120px 0 0 rgba(239, 129, 1, 1), 140px 120px 0 0 rgba(0, 0, 0, 1),
        70px 130px 0 0 rgba(0, 0, 0, 1), 80px 130px 0 0 rgba(255, 174, 77, 1),
        90px 130px 0 0 rgba(208, 114, 0, 1),
        100px 130px 0 0 rgba(255, 255, 255, 1),
        110px 130px 0 0 rgba(226, 226, 226, 1),
        120px 130px 0 0 rgba(214, 212, 212, 1),
        130px 130px 0 0 rgba(239, 129, 1, 1), 140px 130px 0 0 rgba(0, 0, 0, 1),
        70px 140px 0 0 rgba(0, 0, 0, 1), 80px 140px 0 0 rgba(255, 174, 77, 1),
        90px 140px 0 0 rgba(208, 114, 0, 1), 100px 140px 0 0 rgba(239, 129, 1, 1),
        110px 140px 0 0 rgba(226, 226, 226, 1),
        120px 140px 0 0 rgba(255, 139, 0, 1), 130px 140px 0 0 rgba(208, 114, 0, 1),
        140px 140px 0 0 rgba(0, 0, 0, 1), 70px 150px 0 0 rgba(0, 0, 0, 1),
        80px 150px 0 0 rgba(255, 174, 77, 1), 90px 150px 0 0 rgba(255, 144, 0, 1),
        100px 150px 0 0 rgba(255, 139, 0, 1), 110px 150px 0 0 rgba(255, 144, 0, 1),
        120px 150px 0 0 rgba(208, 114, 0, 1), 130px 150px 0 0 rgba(208, 114, 0, 1),
        140px 150px 0 0 rgba(0, 0, 0, 1), 80px 160px 0 0 rgba(0, 0, 0, 1),
        90px 160px 0 0 rgba(255, 155, 34, 1),
        100px 160px 0 0 rgba(255, 155, 34, 1),
        110px 160px 0 0 rgba(208, 114, 0, 1), 120px 160px 0 0 rgba(208, 114, 0, 1),
        130px 160px 0 0 rgba(0, 0, 0, 1), 80px 170px 0 0 rgba(0, 0, 0, 1),
        90px 170px 0 0 rgba(255, 182, 93, 1),
        100px 170px 0 0 rgba(255, 174, 77, 1),
        110px 170px 0 0 rgba(208, 114, 0, 1), 120px 170px 0 0 rgba(208, 114, 0, 1),
        130px 170px 0 0 rgba(0, 0, 0, 1), 90px 180px 0 0 rgba(0, 0, 0, 1),
        100px 180px 0 0 rgba(0, 0, 0, 1), 110px 180px 0 0 rgba(0, 0, 0, 1),
        120px 180px 0 0 rgba(0, 0, 0, 1);
        height: 10px;
        width: 10px;
    }
    57.11%,
    71.4% {
        box-shadow: 100px 30px 0 0 rgba(0, 0, 0, 1), 110px 30px 0 0 rgba(0, 0, 0, 1),
        120px 30px 0 0 rgba(0, 0, 0, 1), 90px 40px 0 0 rgba(0, 0, 0, 1),
        100px 40px 0 0 rgba(255, 182, 93, 1), 110px 40px 0 0 rgba(255, 182, 93, 1),
        120px 40px 0 0 rgba(255, 182, 93, 1), 130px 40px 0 0 rgba(0, 0, 0, 1),
        90px 50px 0 0 rgba(0, 0, 0, 1), 100px 50px 0 0 rgba(247, 159, 57, 1),
        110px 50px 0 0 rgba(247, 159, 57, 1), 120px 50px 0 0 rgba(247, 159, 57, 1),
        130px 50px 0 0 rgba(0, 0, 0, 1), 90px 60px 0 0 rgba(0, 0, 0, 1),
        100px 60px 0 0 rgba(247, 159, 57, 1), 110px 60px 0 0 rgba(249, 154, 39, 1),
        120px 60px 0 0 rgba(247, 159, 57, 1), 130px 60px 0 0 rgba(0, 0, 0, 1),
        90px 70px 0 0 rgba(0, 0, 0, 1), 100px 70px 0 0 rgba(253, 151, 28, 1),
        110px 70px 0 0 rgba(255, 150, 22, 1), 120px 70px 0 0 rgba(255, 150, 22, 1),
        130px 70px 0 0 rgba(0, 0, 0, 1), 90px 80px 0 0 rgba(0, 0, 0, 1),
        100px 80px 0 0 rgba(255, 150, 22, 1), 110px 80px 0 0 rgba(255, 150, 22, 1),
        120px 80px 0 0 rgba(255, 150, 22, 1), 130px 80px 0 0 rgba(0, 0, 0, 1),
        90px 90px 0 0 rgba(0, 0, 0, 1), 100px 90px 0 0 rgba(253, 144, 13, 1),
        110px 90px 0 0 rgba(253, 144, 13, 1), 120px 90px 0 0 rgba(253, 144, 13, 1),
        130px 90px 0 0 rgba(0, 0, 0, 1), 90px 100px 0 0 rgba(0, 0, 0, 1),
        100px 100px 0 0 rgba(253, 144, 13, 1),
        110px 100px 0 0 rgba(253, 144, 13, 1),
        120px 100px 0 0 rgba(253, 144, 13, 1), 130px 100px 0 0 rgba(0, 0, 0, 1),
        90px 110px 0 0 rgba(0, 0, 0, 1), 100px 110px 0 0 rgba(241, 132, 0, 1),
        110px 110px 0 0 rgba(241, 132, 0, 1), 120px 110px 0 0 rgba(241, 132, 0, 1),
        130px 110px 0 0 rgba(0, 0, 0, 1), 90px 120px 0 0 rgba(0, 0, 0, 1),
        100px 120px 0 0 rgba(241, 132, 0, 1), 110px 120px 0 0 rgba(241, 132, 0, 1),
        120px 120px 0 0 rgba(241, 132, 0, 1), 130px 120px 0 0 rgba(0, 0, 0, 1),
        90px 130px 0 0 rgba(0, 0, 0, 1), 100px 130px 0 0 rgba(222, 123, 2, 1),
        110px 130px 0 0 rgba(222, 123, 2, 1), 120px 130px 0 0 rgba(222, 123, 2, 1),
        130px 130px 0 0 rgba(0, 0, 0, 1), 90px 140px 0 0 rgba(0, 0, 0, 1),
        100px 140px 0 0 rgba(222, 123, 2, 1), 110px 140px 0 0 rgba(222, 123, 2, 1),
        120px 140px 0 0 rgba(222, 123, 2, 1), 130px 140px 0 0 rgba(0, 0, 0, 1),
        90px 150px 0 0 rgba(0, 0, 0, 1), 100px 150px 0 0 rgba(208, 114, 0, 1),
        110px 150px 0 0 rgba(208, 114, 0, 1), 120px 150px 0 0 rgba(208, 114, 0, 1),
        130px 150px 0 0 rgba(0, 0, 0, 1), 90px 160px 0 0 rgba(0, 0, 0, 1),
        100px 160px 0 0 rgba(208, 114, 0, 1), 110px 160px 0 0 rgba(208, 114, 0, 1),
        120px 160px 0 0 rgba(208, 114, 0, 1), 130px 160px 0 0 rgba(0, 0, 0, 1),
        90px 170px 0 0 rgba(0, 0, 0, 1), 100px 170px 0 0 rgba(208, 114, 0, 1),
        110px 170px 0 0 rgba(208, 114, 0, 1), 120px 170px 0 0 rgba(208, 114, 0, 1),
        130px 170px 0 0 rgba(0, 0, 0, 1), 100px 180px 0 0 rgba(0, 0, 0, 1),
        110px 180px 0 0 rgba(0, 0, 0, 1), 120px 180px 0 0 rgba(0, 0, 0, 1);
        height: 10px;
        width: 10px;
    }
    71.41000000000001%,
    85.7% {
        box-shadow: 90px 30px 0 0 rgba(0, 0, 0, 1), 100px 30px 0 0 rgba(0, 0, 0, 1),
        110px 30px 0 0 rgba(0, 0, 0, 1), 120px 30px 0 0 rgba(0, 0, 0, 1),
        80px 40px 0 0 rgba(0, 0, 0, 1), 90px 40px 0 0 rgba(193, 105, 3, 1),
        100px 40px 0 0 rgba(193, 105, 3, 1), 110px 40px 0 0 rgba(255, 155, 34, 1),
        120px 40px 0 0 rgba(255, 174, 77, 1), 130px 40px 0 0 rgba(0, 0, 0, 1),
        80px 50px 0 0 rgba(0, 0, 0, 1), 90px 50px 0 0 rgba(193, 105, 3, 1),
        100px 50px 0 0 rgba(255, 139, 0, 1), 110px 50px 0 0 rgba(255, 139, 0, 1),
        120px 50px 0 0 rgba(255, 155, 34, 1), 130px 50px 0 0 rgba(0, 0, 0, 1),
        70px 60px 0 0 rgba(0, 0, 0, 1), 80px 60px 0 0 rgba(193, 105, 3, 1),
        90px 60px 0 0 rgba(255, 139, 0, 1), 100px 60px 0 0 rgba(226, 226, 226, 1),
        110px 60px 0 0 rgba(255, 139, 0, 1), 120px 60px 0 0 rgba(255, 139, 0, 1),
        130px 60px 0 0 rgba(255, 182, 93, 1), 140px 60px 0 0 rgba(0, 0, 0, 1),
        70px 70px 0 0 rgba(0, 0, 0, 1), 80px 70px 0 0 rgba(193, 105, 3, 1),
        90px 70px 0 0 rgba(214, 212, 212, 1),
        100px 70px 0 0 rgba(226, 226, 226, 1),
        110px 70px 0 0 rgba(255, 255, 255, 1), 120px 70px 0 0 rgba(239, 129, 1, 1),
        130px 70px 0 0 rgba(255, 182, 93, 1), 140px 70px 0 0 rgba(0, 0, 0, 1),
        70px 80px 0 0 rgba(0, 0, 0, 1), 80px 80px 0 0 rgba(193, 105, 3, 1),
        90px 80px 0 0 rgba(193, 105, 3, 1), 100px 80px 0 0 rgba(193, 105, 3, 1),
        110px 80px 0 0 rgba(255, 255, 255, 1), 120px 80px 0 0 rgba(255, 139, 0, 1),
        130px 80px 0 0 rgba(255, 182, 93, 1), 140px 80px 0 0 rgba(0, 0, 0, 1),
        70px 90px 0 0 rgba(0, 0, 0, 1), 80px 90px 0 0 rgba(193, 105, 3, 1),
        90px 90px 0 0 rgba(255, 139, 0, 1), 100px 90px 0 0 rgba(193, 105, 3, 1),
        110px 90px 0 0 rgba(255, 255, 255, 1), 120px 90px 0 0 rgba(255, 139, 0, 1),
        130px 90px 0 0 rgba(255, 182, 93, 1), 140px 90px 0 0 rgba(0, 0, 0, 1),
        70px 100px 0 0 rgba(0, 0, 0, 1), 80px 100px 0 0 rgba(193, 105, 3, 1),
        90px 100px 0 0 rgba(214, 212, 212, 1),
        100px 100px 0 0 rgba(226, 226, 226, 1),
        110px 100px 0 0 rgba(255, 139, 0, 1), 120px 100px 0 0 rgba(255, 139, 0, 1),
        130px 100px 0 0 rgba(255, 182, 93, 1), 140px 100px 0 0 rgba(0, 0, 0, 1),
        70px 110px 0 0 rgba(0, 0, 0, 1), 80px 110px 0 0 rgba(193, 105, 3, 1),
        90px 110px 0 0 rgba(193, 105, 3, 1), 100px 110px 0 0 rgba(193, 105, 3, 1),
        110px 110px 0 0 rgba(255, 255, 255, 1),
        120px 110px 0 0 rgba(255, 139, 0, 1),
        130px 110px 0 0 rgba(255, 182, 93, 1), 140px 110px 0 0 rgba(0, 0, 0, 1),
        70px 120px 0 0 rgba(0, 0, 0, 1), 80px 120px 0 0 rgba(193, 105, 3, 1),
        90px 120px 0 0 rgba(255, 139, 0, 1), 100px 120px 0 0 rgba(193, 105, 3, 1),
        110px 120px 0 0 rgba(255, 255, 255, 1),
        120px 120px 0 0 rgba(255, 139, 0, 1),
        130px 120px 0 0 rgba(255, 182, 93, 1), 140px 120px 0 0 rgba(0, 0, 0, 1),
        70px 130px 0 0 rgba(0, 0, 0, 1), 80px 130px 0 0 rgba(193, 105, 3, 1),
        90px 130px 0 0 rgba(214, 212, 212, 1),
        100px 130px 0 0 rgba(226, 226, 226, 1),
        110px 130px 0 0 rgba(255, 255, 255, 1),
        120px 130px 0 0 rgba(239, 129, 1, 1),
        130px 130px 0 0 rgba(255, 182, 93, 1), 140px 130px 0 0 rgba(0, 0, 0, 1),
        70px 140px 0 0 rgba(0, 0, 0, 1), 80px 140px 0 0 rgba(193, 105, 3, 1),
        90px 140px 0 0 rgba(193, 105, 3, 1),
        100px 140px 0 0 rgba(226, 226, 226, 1),
        110px 140px 0 0 rgba(255, 139, 0, 1), 120px 140px 0 0 rgba(255, 139, 0, 1),
        130px 140px 0 0 rgba(255, 182, 93, 1), 140px 140px 0 0 rgba(0, 0, 0, 1),
        70px 150px 0 0 rgba(0, 0, 0, 1), 80px 150px 0 0 rgba(193, 105, 3, 1),
        90px 150px 0 0 rgba(255, 144, 0, 1), 100px 150px 0 0 rgba(255, 139, 0, 1),
        110px 150px 0 0 rgba(255, 144, 0, 1),
        120px 150px 0 0 rgba(255, 155, 34, 1),
        130px 150px 0 0 rgba(255, 182, 93, 1), 140px 150px 0 0 rgba(0, 0, 0, 1),
        80px 160px 0 0 rgba(0, 0, 0, 1), 90px 160px 0 0 rgba(193, 105, 3, 1),
        100px 160px 0 0 rgba(255, 155, 34, 1),
        110px 160px 0 0 rgba(255, 155, 34, 1),
        120px 160px 0 0 rgba(255, 174, 77, 1), 130px 160px 0 0 rgba(0, 0, 0, 1),
        80px 170px 0 0 rgba(0, 0, 0, 1), 90px 170px 0 0 rgba(193, 105, 3, 1),
        100px 170px 0 0 rgba(193, 105, 3, 1),
        110px 170px 0 0 rgba(255, 174, 77, 1),
        120px 170px 0 0 rgba(255, 155, 34, 1), 130px 170px 0 0 rgba(0, 0, 0, 1),
        90px 180px 0 0 rgba(0, 0, 0, 1), 100px 180px 0 0 rgba(0, 0, 0, 1),
        110px 180px 0 0 rgba(0, 0, 0, 1), 120px 180px 0 0 rgba(0, 0, 0, 1);
        height: 10px;
        width: 10px;
    }
    85.71000000000001%,
    100% {
        box-shadow: 80px 30px 0 0 rgba(0, 0, 0, 1), 90px 30px 0 0 rgba(0, 0, 0, 1),
        100px 30px 0 0 rgba(0, 0, 0, 1), 110px 30px 0 0 rgba(0, 0, 0, 1),
        120px 30px 0 0 rgba(0, 0, 0, 1), 130px 30px 0 0 rgba(0, 0, 0, 1),
        70px 40px 0 0 rgba(0, 0, 0, 1), 80px 40px 0 0 rgba(226, 124, 1, 1),
        90px 40px 0 0 rgba(226, 124, 1, 1), 100px 40px 0 0 rgba(226, 124, 1, 1),
        110px 40px 0 0 rgba(226, 124, 1, 1), 120px 40px 0 0 rgba(226, 124, 1, 1),
        130px 40px 0 0 rgba(210, 113, 0, 1), 140px 40px 0 0 rgba(0, 0, 0, 1),
        60px 50px 0 0 rgba(0, 0, 0, 1), 70px 50px 0 0 rgba(226, 124, 1, 1),
        80px 50px 0 0 rgba(226, 124, 1, 1), 90px 50px 0 0 rgba(255, 139, 0, 1),
        100px 50px 0 0 rgba(255, 139, 0, 1), 110px 50px 0 0 rgba(255, 139, 0, 1),
        120px 50px 0 0 rgba(255, 139, 0, 1), 130px 50px 0 0 rgba(226, 124, 1, 1),
        140px 50px 0 0 rgba(239, 129, 1, 1), 150px 50px 0 0 rgba(0, 0, 0, 1),
        50px 60px 0 0 rgba(0, 0, 0, 1), 60px 60px 0 0 rgba(193, 105, 3, 1),
        70px 60px 0 0 rgba(226, 124, 1, 1), 80px 60px 0 0 rgba(214, 212, 212, 1),
        90px 60px 0 0 rgba(193, 105, 3, 1), 100px 60px 0 0 rgba(239, 239, 239, 1),
        110px 60px 0 0 rgba(255, 139, 0, 1), 120px 60px 0 0 rgba(255, 139, 0, 1),
        130px 60px 0 0 rgba(255, 144, 0, 1), 140px 60px 0 0 rgba(255, 155, 34, 1),
        150px 60px 0 0 rgba(239, 129, 1, 1), 160px 60px 0 0 rgba(0, 0, 0, 1),
        50px 70px 0 0 rgba(0, 0, 0, 1), 60px 70px 0 0 rgba(226, 124, 1, 1),
        70px 70px 0 0 rgba(255, 139, 0, 1), 80px 70px 0 0 rgba(214, 212, 212, 1),
        90px 70px 0 0 rgba(226, 226, 226, 1),
        100px 70px 0 0 rgba(239, 239, 239, 1),
        110px 70px 0 0 rgba(255, 255, 255, 1), 120px 70px 0 0 rgba(255, 139, 0, 1),
        130px 70px 0 0 rgba(255, 139, 0, 1), 140px 70px 0 0 rgba(255, 144, 0, 1),
        150px 70px 0 0 rgba(239, 129, 1, 1), 160px 70px 0 0 rgba(0, 0, 0, 1),
        40px 80px 0 0 rgba(0, 0, 0, 1), 50px 80px 0 0 rgba(193, 105, 3, 1),
        60px 80px 0 0 rgba(226, 124, 1, 1), 70px 80px 0 0 rgba(226, 124, 1, 1),
        80px 80px 0 0 rgba(193, 105, 3, 1), 90px 80px 0 0 rgba(193, 105, 3, 1),
        100px 80px 0 0 rgba(193, 105, 3, 1), 110px 80px 0 0 rgba(193, 105, 3, 1),
        120px 80px 0 0 rgba(255, 255, 255, 1), 130px 80px 0 0 rgba(255, 139, 0, 1),
        140px 80px 0 0 rgba(255, 139, 0, 1), 150px 80px 0 0 rgba(255, 144, 0, 1),
        160px 80px 0 0 rgba(247, 159, 57, 1), 170px 80px 0 0 rgba(0, 0, 0, 1),
        40px 90px 0 0 rgba(0, 0, 0, 1), 50px 90px 0 0 rgba(193, 105, 3, 1),
        60px 90px 0 0 rgba(226, 124, 1, 1), 70px 90px 0 0 rgba(255, 139, 0, 1),
        80px 90px 0 0 rgba(255, 139, 0, 1), 90px 90px 0 0 rgba(255, 139, 0, 1),
        100px 90px 0 0 rgba(255, 152, 0, 1), 110px 90px 0 0 rgba(193, 105, 3, 1),
        120px 90px 0 0 rgba(255, 255, 255, 1), 130px 90px 0 0 rgba(255, 139, 0, 1),
        140px 90px 0 0 rgba(255, 139, 0, 1), 150px 90px 0 0 rgba(255, 144, 0, 1),
        160px 90px 0 0 rgba(247, 159, 57, 1), 170px 90px 0 0 rgba(0, 0, 0, 1),
        40px 100px 0 0 rgba(0, 0, 0, 1), 50px 100px 0 0 rgba(193, 105, 3, 1),
        60px 100px 0 0 rgba(226, 124, 1, 1), 70px 100px 0 0 rgba(255, 139, 0, 1),
        80px 100px 0 0 rgba(214, 212, 212, 1),
        90px 100px 0 0 rgba(226, 226, 226, 1),
        100px 100px 0 0 rgba(239, 239, 239, 1),
        110px 100px 0 0 rgba(255, 255, 255, 1),
        120px 100px 0 0 rgba(255, 139, 0, 1), 130px 100px 0 0 rgba(255, 139, 0, 1),
        140px 100px 0 0 rgba(255, 139, 0, 1), 150px 100px 0 0 rgba(255, 144, 0, 1),
        160px 100px 0 0 rgba(247, 159, 57, 1), 170px 100px 0 0 rgba(0, 0, 0, 1),
        40px 110px 0 0 rgba(0, 0, 0, 1), 50px 110px 0 0 rgba(193, 105, 3, 1),
        60px 110px 0 0 rgba(226, 124, 1, 1), 70px 110px 0 0 rgba(255, 139, 0, 1),
        80px 110px 0 0 rgba(193, 105, 3, 1), 90px 110px 0 0 rgba(193, 105, 3, 1),
        100px 110px 0 0 rgba(193, 105, 3, 1), 110px 110px 0 0 rgba(193, 105, 3, 1),
        120px 110px 0 0 rgba(255, 255, 255, 1),
        130px 110px 0 0 rgba(255, 139, 0, 1), 140px 110px 0 0 rgba(255, 139, 0, 1),
        150px 110px 0 0 rgba(255, 144, 0, 1),
        160px 110px 0 0 rgba(247, 159, 57, 1), 170px 110px 0 0 rgba(0, 0, 0, 1),
        40px 120px 0 0 rgba(0, 0, 0, 1), 50px 120px 0 0 rgba(193, 105, 3, 1),
        60px 120px 0 0 rgba(226, 124, 1, 1), 70px 120px 0 0 rgba(255, 139, 0, 1),
        80px 120px 0 0 rgba(255, 139, 0, 1), 90px 120px 0 0 rgba(255, 139, 0, 1),
        100px 120px 0 0 rgba(255, 152, 0, 1), 110px 120px 0 0 rgba(193, 105, 3, 1),
        120px 120px 0 0 rgba(255, 255, 255, 1),
        130px 120px 0 0 rgba(255, 139, 0, 1), 140px 120px 0 0 rgba(255, 139, 0, 1),
        150px 120px 0 0 rgba(255, 144, 0, 1),
        160px 120px 0 0 rgba(247, 159, 57, 1), 170px 120px 0 0 rgba(0, 0, 0, 1),
        40px 130px 0 0 rgba(0, 0, 0, 1), 50px 130px 0 0 rgba(193, 105, 3, 1),
        60px 130px 0 0 rgba(226, 124, 1, 1), 70px 130px 0 0 rgba(255, 139, 0, 1),
        80px 130px 0 0 rgba(214, 212, 212, 1),
        90px 130px 0 0 rgba(226, 226, 226, 1),
        100px 130px 0 0 rgba(239, 239, 239, 1),
        110px 130px 0 0 rgba(255, 255, 255, 1),
        120px 130px 0 0 rgba(255, 139, 0, 1), 130px 130px 0 0 rgba(255, 139, 0, 1),
        140px 130px 0 0 rgba(255, 139, 0, 1), 150px 130px 0 0 rgba(255, 144, 0, 1),
        160px 130px 0 0 rgba(247, 159, 57, 1), 170px 130px 0 0 rgba(0, 0, 0, 1),
        50px 140px 0 0 rgba(0, 0, 0, 1), 60px 140px 0 0 rgba(226, 124, 1, 1),
        70px 140px 0 0 rgba(255, 139, 0, 1), 80px 140px 0 0 rgba(214, 212, 212, 1),
        90px 140px 0 0 rgba(193, 105, 3, 1),
        100px 140px 0 0 rgba(239, 239, 239, 1),
        110px 140px 0 0 rgba(255, 139, 0, 1), 120px 140px 0 0 rgba(255, 139, 0, 1),
        130px 140px 0 0 rgba(255, 139, 0, 1), 140px 140px 0 0 rgba(247, 144, 2, 1),
        150px 140px 0 0 rgba(247, 159, 57, 1), 160px 140px 0 0 rgba(0, 0, 0, 1),
        50px 150px 0 0 rgba(0, 0, 0, 1), 60px 150px 0 0 rgba(193, 105, 3, 1),
        70px 150px 0 0 rgba(226, 124, 1, 1), 80px 150px 0 0 rgba(255, 138, 0, 1),
        90px 150px 0 0 rgba(255, 144, 0, 1), 100px 150px 0 0 rgba(255, 139, 0, 1),
        110px 150px 0 0 rgba(255, 144, 0, 1), 120px 150px 0 0 rgba(255, 139, 0, 1),
        130px 150px 0 0 rgba(255, 155, 34, 1),
        140px 150px 0 0 rgba(255, 155, 34, 1),
        150px 150px 0 0 rgba(247, 159, 57, 1), 160px 150px 0 0 rgba(0, 0, 0, 1),
        60px 160px 0 0 rgba(0, 0, 0, 1), 70px 160px 0 0 rgba(208, 114, 0, 1),
        80px 160px 0 0 rgba(226, 124, 1, 1), 90px 160px 0 0 rgba(255, 155, 34, 1),
        100px 160px 0 0 rgba(255, 155, 34, 1),
        110px 160px 0 0 rgba(255, 155, 34, 1),
        120px 160px 0 0 rgba(255, 155, 34, 1),
        130px 160px 0 0 rgba(255, 174, 77, 1),
        140px 160px 0 0 rgba(247, 159, 57, 1), 150px 160px 0 0 rgba(0, 0, 0, 1),
        70px 170px 0 0 rgba(0, 0, 0, 1), 80px 170px 0 0 rgba(208, 114, 0, 1),
        90px 170px 0 0 rgba(255, 155, 34, 1),
        100px 170px 0 0 rgba(255, 159, 42, 1),
        110px 170px 0 0 rgba(255, 159, 42, 1),
        120px 170px 0 0 rgba(255, 174, 77, 1),
        130px 170px 0 0 rgba(255, 174, 77, 1), 140px 170px 0 0 rgba(0, 0, 0, 1),
        80px 180px 0 0 rgba(0, 0, 0, 1), 90px 180px 0 0 rgba(0, 0, 0, 1),
        100px 180px 0 0 rgba(0, 0, 0, 1), 110px 180px 0 0 rgba(0, 0, 0, 1),
        120px 180px 0 0 rgba(0, 0, 0, 1), 130px 180px 0 0 rgba(0, 0, 0, 1);
        height: 10px;
        width: 10px;
    }
}
