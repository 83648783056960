.header {
    display: flex;
    height: 65px;
    color: white;
    background: grey;
    width: 100%;
    align-items: center;
    flex: 0 0 100%;
    z-index: 4;
    position: relative;
}

.title {
    padding: 0 25px;
    font-size: 35px;
    justify-self: flex-start;
    flex-grow: 1;
}

.user {
    justify-self: flex-end;
    display: flex;
    flex-direction: row;
    padding: 0 25px;
    position: relative;
}

.name {
    padding-left: 10px;
    font-size: 35px;
    display: flex;
    align-items: center;
}

.pfp {
    object-fit: scale-down;
    width: 50px;
    border-radius: 50px;
}

.menu {
    position: absolute;
    width: 200px;
    flex-direction: column;
    right: 0;
    display: flex;
    top: 62px;
    align-items: center;
    background: #031e11;
    border: 3px solid grey;
}

.menu > div {
    width: 100%;
    border: 3px solid #031e11;
    border-bottom: none;
    background: grey;
    height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    justify-content: center;
}

.menu > div:last-of-type {
    border-bottom: 3px solid #031e11;
}