#fireworks {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 90%;
    z-index: -1;
}

#fireworks > canvas {
    position: absolute;
    bottom: 0;
}