.home-page-welcome {
    text-align: center;
    margin: 30px 0 0 0;
}

.home-page-welcome > h2 {
    font-size: 40px;
    margin: 0;
}

.home-page-welcome > h1 {
    font-size: 155px;
    margin: 0;
}

.podiums {
    margin: 100px auto 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: baseline;
}