
.wager-header {
    text-align: center;
}

.wager-coins-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
}

.wager-button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.wager-category-closed {
    color: darkslategrey;
}

.wager-button > button {
    padding: 10px 20px;
}

.wager-button > button:disabled {
    border: 1px solid black;
    color: darkslategrey;
}

.wager-coins-counter > span {
    margin-right: 10px;
}

.wagers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.wager {
    width: 35%;
    margin: 0 50px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 35%;
}

.wager-user {
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.wager-name-container {
    display: flex;
    margin-left: 15px;
    flex-direction: column;
}
.wager-name-container > p {
    margin: 0;
}

.wager-field {
    margin-left: auto;
    display: flex;
}

.wager-x {
    font-size: 30px;
}

.wager-amount {
    position: relative;
    font-size: 30px;
    margin-left: 15px;
    width: 30px;
}

.arrow {
    border: solid #14fdce;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 5px;
    position: absolute;
    margin-left: 25px;
}

.up {
    top: 0;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.down {
    bottom: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.bet-disabled {
    border: solid darkslategrey;
    border-width: 0 3px 3px 0;
    pointer-events: none;
}